import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            component: Home
        },
        {
            path: '/:village',
            redirect: '/0/:village'
        },
        {
            path: '/:site/:village/:dealId/:type/',
            name: 'deal',
            component: Home
        },
        {
            path: '/edit/:village',
            name: 'edit',
            component: Home
        },
        {
            path: '/lands/:village',
            name: 'lands',
            component: Home
        },
        {
            path: '/square/:village',
            name: 'square',
            component: Home
        },
        {
            path: '/:site/:village',
            name: 'home',
            component: Home
        },
        {
            path: '*',
            redirect: '/'
        },
    ],
    mode: 'hash'
})

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import Meta from 'vue-meta'
import VeeValidate from 'vee-validate'
import VueScrollTo from 'vue-scrollto'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import checkView from 'vue-check-view'
import YmapPlugin from 'vue-yandex-maps'
import { Datetime } from 'vue-datetime';
import PrettyCheckbox from 'pretty-checkbox-vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import ToggleButton from 'vue-js-toggle-button'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Vue2TouchEvents from 'vue2-touch-events'

import 'pretty-checkbox/src/pretty-checkbox.scss';

const VueInputMask = require('vue-inputmask').default;
import 'vue-datetime/dist/vue-datetime.css';
Vue.use(CoolLightBox)
Vue.use(ToggleButton);
Vue.use(VueAxios, axios);
Vue.use(PrettyCheckbox);
Vue.component('datetime', Datetime);
Vue.use(VueInputMask);
Vue.use(checkView);
Vue.use(Vue2TouchEvents)
const settings = {
  apiKey: 'bf014650-2cc4-46de-a6d4-034052108e2d',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
  controls: ['smallMapDefaultSet']
};
Vue.use(YmapPlugin, settings);
numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal : function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: 'руб.'
  }
});
numeral.locale('ru');
Vue.filter('numFormat', numFormat(numeral));
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
Vue.use(VeeValidate);
Vue.config.productionTip = false;
Vue.use(VueCookies);

Vue.use(Meta);

new Vue({
  router,
  store,
  render: h => h(App),
  /*mounted() {
    document.dispatchEvent(new Event('render-event'))
  }*/
}).$mount('#app');

<template>
  <div class="body" id="body">
    <div id="app_get_stocks"></div>
    <div class="header-layer"></div>
    <div class="master-plan__panel">
      <div class="master-plan__item"><span style="background: #59B63F;"></span>Свободен</div>
      <div class="master-plan__item"><span style="background: #E6E90E;"></span>Забронирован</div>
      <div class="master-plan__item"><span style="background: #E61E0E;"></span>Задаток / Продан</div>
      <div class="master-plan__item"><span style="background: #ABAE9E;"></span>Недоступен</div>
    </div>
    <div class="header-mobile">
      <div class="header-mobile-current-village" @click="toggleMobileListVillages">
        <div class="header-mobile-current-village_name">
          <img :src="currentVillage.PROPERTY_516"/>
          <span>{{ currentVillage.NAME }}</span>
        </div>
        <div class="header-mobile-current-village_arrow">
          <img v-if="!showListVillages" src="/assets/images/arrowDown.svg">
          <img v-if="showListVillages" src="/assets/images/arrowUp.svg">
        </div>
      </div>
      <div class="filter-values-button" @click="toogleCallback">
        Заявка
      </div>

    </div>
    <div class="header">
      <!--      <div class="header-top">-->
      <!--        <div class="header-left">-->
      <!--          <div class="header-left-logo">-->
      <!--            <img src="/assets/images/logoMain.jpg">-->
      <!--          </div>-->
      <!--          &lt;!&ndash;                <div class="header-left-filter" @click="filter.show = !filter.show">-->
      <!--                              <img src="/assets/images/filter.svg"/>-->
      <!--                          </div>&ndash;&gt;-->
      <!--        </div>-->
      <!--        <div class="header-right" v-bind:class="{active: showAllVill}">-->
      <!--          <transition name="fade">-->
      <!--            <div class="header-villages" v-bind:key="52353563" v-if="showListVillages">-->
      <!--              <div v-for="(vill, i) in villages"-->
      <!--                   :class="($route.params.village == vill.ID || currentVillage.ID == vill.ID)? 'header-villages-village active-village': 'header-villages-village'"-->
      <!--                   :data-to="vill.ID" v-bind:key="i" :data-coords="vill.PROPERTY_510"-->
      <!--                   v-show="(vill.PROPERTY_521 == '340' || showAllVill) && (vill.PROPERTY_526 == site || site == '0') || $route.path.includes('square')">-->
      <!--                <div class="header-villages-village-logo">-->
      <!--                  <img :src="vill.PROPERTY_516"/>-->
      <!--                </div>-->
      <!--                <div class="header-villages-village-name" :data-to="vill.ID" v-bind:key="i"-->
      <!--                     :data-coords="vill.PROPERTY_510"-->
      <!--                     v-touch:tap="goTo"-->
      <!--                     @mouseover="hoverVillage = villages.find(villag => villag.ID === vill.ID);"-->
      <!--                     @mouseleave="hoverVillage = currentVillage;">-->
      <!--                  {{ vill.NAME }}-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class="header-rigth-all" @click="showAllVill = !showAllVill" v-if="showAllVill">-->
      <!--                Свернуть-->
      <!--                <div style="transform: rotate(-90deg); color:#9EADBA; margin-left: .5vh">></div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </transition>-->
      <!--          <div class="header-rigth-all" @click="showAllVill = !showAllVill" v-if="!showAllVill">-->
      <!--            Все&#160;поселки&#160;>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <transition class="bg-gray" name="fade">
        <div v-if="showDetailVillage" class="container detail" key="846321313">
          <div class="detail-left">
            <div class="detail-left-logo">
              <img :src="hoverVillage.PROPERTY_516"/>
            </div>
            <div class="detail-left-name">
              {{ hoverVillage.NAME }}
            </div>
          </div>
          <div class="detail-right">
            <div class="detail-right-props">
              <div class="detail-right-props-prop" v-if="hoverVillage.PROPERTY_524">
                <div class="detail-right-props-prop-image">
                  <img src="/assets/images/price.svg"/>
                </div>
                <div class="detail-right-props-prop-name">
                  ≈ {{ hoverVillage.PROPERTY_524 }} тыс.₽
                </div>
              </div>
              <div class="detail-right-props-prop" v-if="hoverVillage.PROPERTY_523">
                <div class="detail-right-props-prop-image">
                  <img :src="(hoverVillage.PROPERTY_359)?'/assets/images/fire.svg':'/assets/images/fireoff.svg'"/>
                </div>
                <div class="detail-right-props-prop-name">
                  Газ: {{ hoverVillage.PROPERTY_523 }}
                </div>
              </div>
              <div class="detail-right-props-prop">
                <div class="detail-right-props-prop-image">
                  <img :src="(hoverVillage.PROPERTY_360)?'/assets/images/water.svg' : '/assets/images/wateroff.svg'"/>
                </div>
                <div class="detail-right-props-prop-name">
                  Вода
                </div>
              </div>
              <div class="detail-right-props-prop">
                <div class="detail-right-props-prop-image">
                  <img :src="(hoverVillage.PROPERTY_358)? '/assets/images/road.svg' :'/assets/images/roadoff.svg'"/>
                </div>
                <div class="detail-right-props-prop-name">
                  Дороги
                </div>
              </div>
              <div class="detail-right-props-prop">
                <div class="detail-right-props-prop-image">
                  <img
                      :src="(hoverVillage.PROPERTY_358)? '/assets/images/electrification.svg' : '/assets/images/electrificationoff.svg'"/>
                </div>
                <div class="detail-right-props-prop-name">
                  Эл-во
                </div>
              </div>
              <!--              <div class="detail-right-props-prop" v-if="hoverVillage.PROPERTY_522">-->
              <!--                <div class="detail-right-props-prop-image">-->
              <!--                  <img src="/assets/images/peaple.svg"/>-->
              <!--                </div>-->
              <!--                <div class="detail-right-props-prop-name">-->
              <!--                  {{ hoverVillage.PROPERTY_522 }}-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <div class="detail-right-buttons">
              <div class="detail-right-buttons-container">
                <a v-if="hoverVillage.PROPERTY_533" class="detail-right-more" :href="hoverVillage.PROPERTY_533"
                   target="_blank">
                  Подробнее
                </a>
                <div class="filter-values-button" @click="toogleCallback">
                  Оставить заявку
                </div>
              </div>
              <div class="detail-right-close">
                <!--                <img src="/assets/images/close.svg" @click="closeDetailVill">-->
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div class="callback" v-if="showCallback" key="684646843213">
        <div class="callback-content">
          <div class="popup-close-swipe" v-touch:swipe="closeCallback">
            <img src="/assets/images/arrow-down.png">
          </div>
          <div class="callback-content-close">
            <img @click="toogleCallback" src="/assets/images/close.svg">
          </div>
          <div class="callback-content-head">
            Заказать звонок
          </div>
          <div class="callback-content-descr">
            Введите данные и нажмите заказать, и мы свяжеимся с Вами в течении часа.
          </div>
          <div class="callback-content-inputs">
            <div class="callback-content-inputs-input">
              <float-label>
                <input v-model="order.name"
                       type="text"
                       placeholder="Ваше Имя"
                       v-bind:class="{ error: orderError.name}">
              </float-label>
            </div>
            <div class="callback-content-inputs-input">
              <float-label>
                <input v-model="order.phone"
                       type="text"
                       placeholder="Телефон"
                       v-mask="'+7 (###) ### ## ##'"
                       v-bind:class="{ error: orderError.phone}">
              </float-label>
            </div>
            <div class="callback-content-inputs-input">
              <float-label>
                <input v-model="order.mail" type="email" placeholder="Эл. Почта">
              </float-label>
            </div>
            <div class="callback-content-inputs-input">
              <float-Label>
                <textarea v-model="order.comment" placeholder="Введите комментарий"></textarea>
              </float-Label>
            </div>
            <div class="callback-content-button" @click="newOrder">
              Заказать звонок
            </div>
            <div class="callback-content-note">
              *Нажимая на кнопку "Записаться" вы принимаете условия<br>
              <a href="#" target="_blank"> Соглашения об использовании сайта</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="loading && $route.params.village" class="loading">
      <texture v-if="timer > 0 && !isMobile" style="
                    position: absolute;
                    top: 50vh;
                    left: calc(50vw - 20px);z-index: 999;
                        background: linear-gradient(45deg, transparent 49%, #FF5C00 50%, #FF5C00 50%, transparent 51%, transparent), linear-gradient(-45deg, transparent 49%, #FF5C00 50%, #FF5C00 50%, transparent 51%, transparent);
                        border: 1px #FF5C00 solid;
                        background-size: 16px 16px;
                        background-position: 0% 0%;"></texture>
      <texture v-else-if="timer > 0 && isMobile"
               :size="'5.8vh'"
               style="
                    position: absolute;
                    top: 50vh;
                    left: calc(50vw - 2.9vh);
                    z-index: 999;
                        background: linear-gradient(45deg, transparent 49%, #FF5C00 50%, #FF5C00 50%, transparent 51%, transparent), linear-gradient(-45deg, transparent 49%, #FF5C00 50%, #FF5C00 50%, transparent 51%, transparent);
                        border: 1px #FF5C00 solid;
                        background-size: 16px 16px;
                        background-position: 0% 0%;"></texture>
      <div v-if="!isMobile && timer > 0" style="background-color: #ffffff; width: 24vh; height: 14vh;position: absolute;top: 43vh;left: calc(50vw - 12vh);border-radius: 10px;">
        <div style="margin: auto;width: fit-content;margin-top: 1vh;font-weight: bold; font-size: 2vh">Генплан</div>
        <div style="margin: auto;width: fit-content;margin-top: 0.5vh;font-size: 1.5vh">Загружается ...({{timer}})</div>
      </div>
      <div v-else-if="timer > 0" style="background-color: #ffffff; width: 24vh; height: 18vh;position: absolute;top: 40vh;left: calc(50vw - 12vh);border-radius: 10px;">
        <div style="margin: auto;width: fit-content;margin-top: 2vh;font-weight: bold; font-size: 3vh">Генплан</div>
        <div style="margin: auto;width: fit-content;margin-top: 0.5vh;font-size: 1.5vh">Загружается ...({{timer}})</div>
      </div>
    </div>

    <div v-if="showMap" class="map">
      <yandex-map
          map-type="hybrid"
          :coords="coordsCenter"
          :zoom="12"
          :controls="['zoomControl']"
          v-if="currentVillage.ID"
          @map-was-initialized="firstMapInit">
        <ymap-marker
            v-for="overlay in mapShowOverlayEdit"
            :key="overlay.ID + 'squareEdit'"
            :coords="[overlay.PROPERTY_532]"
            :marker-id="overlay.ID + 'squareEdit'"
            :properties="{ID: overlay.ID, type: 'squareEdit'}"
            :options="overlayEditMapOptions(overlay)"
            marker-type="Polygon">
        </ymap-marker>
        <ymap-marker
            v-for="overlay in mapShowOverlay"
            :key="overlay.ID + 'ovrl'"
            :coords="[overlay.PROPERTY_506]"
            :marker-id="overlay.ID + 'ovrl'"
            :marker-fill="{imageHref: overlay.PROPERTY_515}"
            :properties="{ID: overlay.ID, type: 'overlay'}"
            :options="overlayMapOptions"
            marker-type="Polygon">
        </ymap-marker>
        <ymap-marker
            v-for="village in mapShowVillages"
            :key="village.ID"
            :coords="[village.PROPERTY_532]"
            :marker-id="village.ID"
            :hint-content="getVillageHintContentHover(village)"
            :marker-fill="{color: village.PROPERTY_534, opacity: 0.5}"
            :options="villageMapOptions"
            :properties="{ID: village.ID, fillColor: village.PROPERTY_534, type: 'village'}"
            @click="clickVillage"
            @mouseenter="mouseEnterVillage"
            @mouseleave="mouseLeaveVillage"
            marker-type="Polygon">
        </ymap-marker>
        <ymap-marker
            v-for="land in mapShowLands"
            :key="land.id"
            :coords="land.coords"
            :marker-id="land.id"
            :options="land.options"
            :properties="land.properties"
            @click="clickLand"
            @click.right="clickLandShift"
            @mouseenter="mouseEnterLand"
            @mouseleave="mouseLeaveLand"
            marker-type="Polygon">
        </ymap-marker>
        <ymap-marker
            v-for="land in mapShowLandsNumber"
            :key="land.id + 'number'"
            :marker-id="land.id + 'number'"
            :coords="land.center"
            :options="land.options"
            :properties="land.properties"
            marker-type="Placemark"

        >
        </ymap-marker>
      </yandex-map>
      <transition name="fade">
        <div v-if="showLandData" class="popup" key="5135">
          <div class="popup-close-swipe" v-touch:swipe="closeDetail">
            <img src="/assets/images/arrow-down.png">
          </div>
          <div class="popup-close" @click="closeDetail">
            <img src="/assets/images/close.svg">
          </div>
          <div class="popup-head">
            {{ choosedLandData.weaving }} соток
          </div>
          <div class="popup-info">
            <div class="popup-info-litera">
              Участок № {{ choosedLandData.pieceNumber }}
            </div>
            <div class="popup-info-cadastr">
              K№
              <a target="_blank" :href="choosedLandData.rosreestrLink">
                {{ choosedLandData.rosreestrString }}
              </a>
            </div>
          </div>
          <div class="popup-price">
            <transition name="slide-down">
              <div v-if="showDetail" class="popup-price-prices" key="8463">
                <div class="popup-price-prices-item">
                  <div class="popup-price-prices-item-name">
                    Участок
                  </div>
                  <div v-if="choosedLandData.showDiscount" class="popup-price-prices-item-discount">
                    - {{ choosedLandData.discountToLand }} ₽
                  </div>
                  <div class="popup-price-prices-item-price"
                       v-if="choosedLandData.showDiscountPrice"
                       :class="{ orange: !addDiscuont }">
                    {{ choosedLandData.discountPriceLand }} ₽
                  </div>
                  <div class="popup-price-prices-item-price"
                       v-if="choosedLandData.showPrice"
                       :class="{ orange: !addDiscuont }">
                    {{ choosedLandData.priceLand }} ₽
                  </div>
                </div>
                <div class="popup-price-prices-item">
                  <div class="popup-price-prices-item-name">
                    Коммуникации
                  </div>
                  <div class="popup-price-prices-item-price"
                       :class="{ orange: !addDiscuont }">
                    {{ choosedLandData.priceUtilities }} ₽
                  </div>
                </div>
                <div class="popup-price-prices-item" v-if="choosedLandData.showDiscountPercent">
                  <div class="popup-price-prices-item-name">
                    Скидка
                  </div>
                  <div class="control-group control-checkbox popup-price-prices-item-checkbox">
                    <label class="control control-checkbox">
                      <input type="checkbox" v-model="addDiscuont">
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                  <div v-if="addDiscuont" class="popup-price-head-discount">
                    - {{ choosedLandData.discountPercent }} %
                  </div>
                  <div v-if="!addDiscuont && choosedLandData.showDiscountPercent"
                       class="popup-price-head-discount-inactive">
                    - {{ choosedLandData.discountPercent }} %
                  </div>
                  <div v-if="choosedLandData.showDiscountPrice" class="popup-price-prices-item-price discount-active">
                    - {{ choosedLandData.discountToLand }} ₽
                  </div>
                  <div v-if="choosedLandData.showPrice" class="popup-price-prices-item-price discount-inactive">
                    - {{ choosedLandData.discountToLand }} ₽
                  </div>
                </div>
              </div>
            </transition>
            <div class="popup-price-head" @click="toogleDetail">
              <div> Итого стоимость:
                <img v-if="!showDetail" src="/assets/images/arrowDown.svg">
                <img v-if="showDetail" src="/assets/images/arrowUp.svg">
              </div>
              <div v-if="choosedLandData.showDiscountPrice" class="popup-price-head-price"
                   :class="{ orange: !addDiscuont }">
                {{ choosedLandData.totalDiscountPrice }} ₽
              </div>
              <div v-if="choosedLandData.showPrice" class="popup-price-head-price" :class="{ orange: !addDiscuont }">
                {{ choosedLandData.totalPrice }} ₽
              </div>
            </div>
          </div>
          <div v-if="choosedLandData.statusLand === '299' && !$route.params.dealId" @click="toogleCallback"
               class="popup-button">
            <span>Заказать звонок</span>
          </div>
          <div v-else-if="$route.params.dealId" @click="addToDeal" class="popup-button">
            <span>Добавить в сделку</span>
          </div>
          <div v-else-if="choosedLandData.statusLand === '301'" class="popup-button-sold">
            <img src="/assets/images/lock.svg">
            <span>Продан</span>
          </div>
          <div v-else-if="choosedLandData.statusLand === '302'" class="popup-button-blocked">
            <img src="/assets/images/lock.svg">
            <span>Недоступен</span>
          </div>
          <div v-else class="popup-button-blocked">
            <img src="/assets/images/lock.svg">
            <span>Занят</span>
          </div>
          <div class="popup-button-more">
            <a :href="'https://www.invest-n.ru/getfrombx24/redirect/?kadastr='+choosedLandData.rosreestrString"
               target="_blank">
              Подробнее
            </a>
          </div>
          <div class="images-wrapper" v-if="choosedLandData.slides">
            <div v-for="(slide, index) in visibleSlides" :key="index"
                 class="image"
                 @click="slider.index = index"
                 :style="{ backgroundImage: 'url(' + slide + ')' }">
            </div>
          </div>
          <gallery key="68463464"
                   :images="choosedLandData.slides"
                   :index="slider.index"
                   @close="slider.index = null">
          </gallery>
        </div>
      </transition>
      <transition v-if="$route.name === 'lands' && showLandPropertyUpdate" name="fade" class="container-land-property">
        <div v-if="showLandPropertyUpdate" key="358941" class="container-land-property-update">
          <form v-on:submit.prevent="saveLandProperties">
            <float-label v-for="(description, property) in landPropertiesUpdate" :key="property">
              <input :name="property"
                     v-model="landsUpdateForm[property]"
                     type="text"
                     :placeholder="description">
            </float-label>
            <button type="submit">Сохранить</button>
          </form>
        </div>
      </transition>
    </div>

    <div v-if="$route.name === 'square'" class="right-side-panel">
      <button @click="setCoordinatesSquare">Сохранить подложку</button>
    </div>
    <div v-else-if="$route.name === 'edit'" class="right-side-panel">
      <button @click="setCoordinatesEdit">Сохранить подложку</button>
    </div>
    <div v-else-if="$route.name === 'lands'" class="right-side-panel">
      <button v-if="showLandPropertyUpdate" @click="disableChoseAllLands">
        Снять выделение
      </button>
      <button @click="choseAllLands">
        Выбрать все
      </button>
    </div>

    <chrome-picker v-if="$route.path.includes('square')"
                   style="position: absolute; top:30vh;right: 10vw"
                   v-model="colors"
                   @input="updateColor">
    </chrome-picker>
  </div>
</template>

<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import config from '../config';
import {Texture} from 'vue-loading-spinner'
import Transition from "vuikit/src/library/notification/transition";
import FloatLabel from "vue-float-label/components/FloatLabel";
import VueGallery from 'vue-gallery';
import {Chrome} from 'vue-color'

export default {
  components: {
    'gallery': VueGallery,
    'chrome-picker': Chrome,
    Transition,
    Texture,
    FloatLabel,
    yandexMap,
    ymapMarker,
  },
  data() {
    return {
      timer: 7,
      timing: null,
      landsUpdateForm: {},
      landsJoinPropertyDescription: {
        PROPERTY_364: 'Цена за сотку',
        PROPERTY_365: 'Скидка % на сотку',
        PROPERTY_548: 'Тестовое поле'
      },
      landsPropertyDescription: {
        PROPERTY_363: 'Статус участка',
        PROPERTY_366: 'Площадь соток',
        PROPERTY_377: 'Кадастровый номер',
        PROPERTY_412: 'Цена участка',
        PROPERTY_437: 'Литера',
        PROPERTY_507: 'Координаты',
      },
      landsChoseToEdit: [],
      landsChoseColor: "#9500ff",
      overlayMapOptions: {
        zIndex: 99,
        zIndexHover: 999,
        hasBalloon: false,
        draggable: false,
        hasHint: false,
        cursor: 'default',
        fillOpacity: 1,
        stroke: false
      },
      villageMapOptions: {
        zIndex: 999,
        zIndexHover: 999,
        hasBalloon: true,
        hasHint: true,
        fillOpacity: 0.5,
        fillMethod: 'tile',
        stroke: true,
        strokeColor: "#000000",
        strokeWidth: .3
      },
      isMobile: false,
      colors: {
        hex: '#194d33',
        hex8: '#194D33A8',
        hsl: {h: 150, s: 0.5, l: 0.2, a: 1},
        hsv: {h: 150, s: 0.66, v: 0.30, a: 1},
        rgba: {r: 25, g: 77, b: 51, a: 1},
        a: 1
      },
      showListVillages: true,
      slider: {
        index: null
      },
      choosedVill: this.$route.params.village,
      showCallback: false,
      order: {
        name: null,
        phone: null,
        mail: null,
        comment: null
      },
      orderError: {
        name: false,
        phone: false,
      },
      site: this.$route.params.site,
      showAllVill: false,
      showDetailVill: true,
      winHeight: window.innerHeight,
      filter: {
        show: false,
        gas: false,
        water: false,
        roads: false,
        elctr: false,
        live: false,
        minPrice: null,
        maxPrice: null
      },
      hoverVillage: {},
      currentVillage: {},
      map: [],
      choosedLand: null,
      showDetail: false,
      addDiscuont: true,
      url: config.backend_url,
      coordsCenter: [55.75370903771494, 37.61981338262558],
      config: config,
      villageOverlay: {},
      villages: [],
      villageToMap: false,
      villagesSquareMapEdit: {},
      lands: [],
      landsMap: [],
      centerLands: [],
      layer: null,
      showMap: false,
      loading: true,
      color: {
        299: "#3CFF4F",
        300: "#FFE600",
        301: "#D22020",
        302: "#b5b5b5",
        304: "#D22020",
        1080: "#00aeef"
      },
      status: {
        299: "Свободен",
        300: "Забронирован",
        301: "Продан",
        302: "Недоступен",
        304: "Задаток",
        1080: "Свободен"
      },
    }
  },
  computed: {
    showDetailVillage: function () {
      return (this.$route.name === 'lands') ? false : this.showDetailVill;
    },
    showLandData: function () {
      return this.choosedLand != null && this.lands.find(land => land.ID === this.choosedLand)
    },
    choosedLandData: function () {
      let data = this.lands.find(land => land.ID === this.choosedLand);
      let statusLand = data.PROPERTY_363;
      let slides = data.PROPERTY_528;

      let weavingLand = parseFloat(data.PROPERTY_366);
      let priceToWeaving = parseFloat(data.PROPERTY_364);
      let discountToWeaving = parseFloat(data.PROPERTY_365);
      let priceLand = this.roundingTen(weavingLand * priceToWeaving);
      let discountToLand = this.roundingTen(priceLand * (discountToWeaving / 100));
      let discountPriceLand = parseInt(priceLand - discountToLand);

      let priceInfrastructure = parseInt(data.PROPERTY_367);
      let priceGasPipeline = parseInt(data.PROPERTY_369);
      let priceWaterPipeline = parseInt(data.PROPERTY_371);
      let priceUtilities = priceInfrastructure + priceGasPipeline + priceWaterPipeline;

      let showDiscountPrice = this.addDiscuont && weavingLand;
      let showDiscountPercent = discountToWeaving > 0;
      let showDiscount = showDiscountPrice && showDiscountPercent;
      let showPrice = !this.addDiscuont && weavingLand;

      let totalDiscountPrice = discountPriceLand + priceUtilities;
      let totalPrice = priceLand + priceUtilities;

      return {
        data: data,
        statusLand: statusLand,
        slides: slides,
        weaving: weavingLand,
        pieceNumber: data.PROPERTY_437,
        rosreestrString: data.PROPERTY_377,
        rosreestrLink: this.getRosreestrLink(data.PROPERTY_377),
        priceLand: priceLand.toLocaleString(),
        discountPriceLand: discountPriceLand.toLocaleString(),
        discountPercent: discountToWeaving,
        showPrice: showPrice,
        showDiscountPrice: showDiscountPrice,
        showDiscountPercent: showDiscountPercent,
        showDiscount: showDiscount,
        discountToLand: discountToLand.toLocaleString(),
        priceUtilities: priceUtilities.toLocaleString(),
        totalDiscountPrice: totalDiscountPrice.toLocaleString(),
        totalPrice: totalPrice.toLocaleString(),
      }
    },
    visibleSlides: function () {
      return this.choosedLandData.slides.slice(0, 4);
    },
    mapShowOverlay: function () {
      return [this.currentVillage];
    },
    mapShowOverlayEdit: function () {
      return this.$route.path.includes('square') ? [this.currentVillage] : [];
    },
    mapShowVillages: function () {
      return this.villages.filter(village => village.ID !== this.currentVillage.ID);
    },
    mapShowLands: function () {
      let self = this;
      let resultLands = [];
      this.lands.forEach(function (land) {
        let resultObj = {
          id: land.ID,
          coords: [land.PROPERTY_507],
          options: {
            zIndex: 999,
            zIndexHover: 999,
            hasBalloon: true,
            hasHint: true,
            fillColor: self.getLandFillColor(land),
            fillOpacity: 0.5,
            fillMethod: 'tile',
            stroke: true,
            strokeColor: "#000000",
            strokeWidth: .3,
          },
          properties: {
            ID: land.ID,
            fillColor: self.getLandFillColor(land),
            type: 'land',
            hintContent: self.getLandHintContentHover(land),
            data: land,
          }
        }
        resultLands.push(resultObj);
      });
      return resultLands;
    },
    mapShowLandsNumber: function () {
      return this.landsMap;
    },
    landPropertiesUpdate: function () {
      return this.landsJoinPropertyDescription;
    },
    showLandPropertyUpdate: function () {
      return this.landsChoseToEdit.length > 0;
    },
  },
  methods: {
    startTimer(){
      let self = this;
      self.timing = setInterval( function (){
        if (self.timer == 0){
          clearInterval(self.timing)
          // self.loading = false;
        }
        else {
          self.timer--;
        }
      }, 1000);
    },
    overlayEditMapOptions(overlay) {
      this.colors.hex = (overlay.PROPERTY_534) ? overlay.PROPERTY_534 : "#000000"
      return {
        editorDrawingCursor: "crosshair",
        editorMaxPoints: 300,
        fillColor: this.colors.hex,
        strokeColor: '#0000FF',
        fillOpacity: 0.5,
        strokeWidth: 1
      }
    },
    mouseEnterVillage(e) {
      let id = e.get('target').properties.get('ID');
      this.hoverVillage = this.villages.find(village => village.ID === id);
    },
    mouseLeaveVillage() {
      this.hoverVillage = this.currentVillage;
    },
    clickVillage(e) {
      let village = e.get('target');
      let id = village.properties.get('ID');
      this.villageToMap = village;
      this.timer = 4;
      this.startTimer();
      this.goToVillage(id);
    },
    mouseEnterLand(e) {
      let land = e.get('target');
      let id = land.properties.get('ID');
      if (!this.landsChoseToEdit.includes(id)) {
        land.options.set("fillColor", '#FFFFFF');
        land.options.set("strokeWidth", .3);
        land.options.set("strokeColor", "#FF5C00");
        land.properties.set("active", true);
      }
    },
    mouseLeaveLand(e) {
      let land = e.get('target');
      let id = land.properties.get('ID');
      if (id !== this.choosedLand && !this.landsChoseToEdit.includes(id)) {
        land.options.set("fillColor", land.properties.get('fillColor'));
        land.options.set("strokeWidth", .1);
        land.options.set("strokeColor", "#000000");
        land.properties.set("active", false);
      }
    },
    clickLand(e) {
      let mapLand = e.get('target');
      let id = mapLand.properties.get('ID');
      let self = this;
      this.addDiscuont = true;
      this.choosedLand = id;

      this.map.geoObjects.each(function (geoCollection) {
        geoCollection.each(function (ob) {
          let obId = ob.properties.get('ID');
          if (obId !== id && ob.properties.get('active') && !self.landsChoseToEdit.includes(obId))
            ob.options.set("fillColor", ob.properties.get('fillColor'));
        });
      });
    },
    clickLandShift(e) {
      if (this.$route.name === 'lands') {
        let data = [];
        let land = e.get('target');
        let id = land.properties.get('ID');
        land.options.set("fillColor", this.landsChoseColor);
        if (this.landsChoseToEdit.includes(id)) {
          this.landsChoseToEdit.splice(this.landsChoseToEdit.indexOf(id), 1)
          land.options.set("fillColor", land.properties.get('fillColor'));
        } else {
          this.landsChoseToEdit.push(id)
          data.push(land.properties.get('data'));
        }
      }
    },
    saveLandProperties() {
      this.loading = true;
      let data = {
        properties: this.landsUpdateForm,
        ids: this.landsChoseToEdit,
      };
      this.axios.post(this.url + "Bx24/savePropertiesLands/", data).then((response) => {
        window.console.log(response.data);
        if (response.data.result) {
          alert("Все сохранилось!");
        } else {
          alert("Какие-то проблемы, попробуйте еще раз.");
        }
        this.loading = false;
      });
    },
    choseAllLands() {
      let self = this;
      this.map.geoObjects.each(function (geoCollection) {
        geoCollection.each(function (ob) {
          let obId = ob.properties.get('ID');
          if (ob.properties.get('type') === 'land' && !self.landsChoseToEdit.includes(obId)) {
            ob.options.set("fillColor", self.landsChoseColor);
            self.landsChoseToEdit.push(obId);
          }
        });
      });
    },
    disableChoseAllLands() {
      this.map.geoObjects.each(function (geoCollection) {
        geoCollection.each(function (ob) {
          if (ob.properties.get('type') === 'land') {
            ob.options.set("fillColor", ob.properties.get('fillColor'));
          }
        });
      });
      this.landsChoseToEdit = [];
    },
    roundingTen(val) {
      return Math.round(val / 100) * 100;
    },
    getRosreestrLink(rosreestrString) {
      return 'https://pkk.rosreestr.ru/#/search/?text=' +
          rosreestrString +
          '&type=1&opened=' +
          parseInt(rosreestrString.split(':')[0]) +
          ':' + parseInt(rosreestrString.split(':')[1]) +
          ':' + parseInt(rosreestrString.split(':')[2]) +
          ':' + parseInt(rosreestrString.split(':')[3]);
    },
    newOrder() {
      let self = this;
      let land = undefined;
      if (this.choosedLand) {
        land = this.lands.find(land => land.ID === this.choosedLand)
      }
      let data = {
        village: this.$route.params.village,
        villageName: this.currentVillage.NAME,
        order: this.order,
        land: (land) ? land.ID : null,
        landName: (land) ? land.NAME : null
      }
      this.orderError.name = !(this.order.name.length > 1);
      this.orderError.phone = !(this.order.phone.length > 1);
      if (!this.orderError.name && !this.orderError.phone) {
        self.showCallback = false;
        this.axios.post(this.url + "Bx24/setOrder/", data).then((response) => {
          if (response.data.result) {
            alert("Вы успешно оставили заявку скоро наберем!");
          } else {
            alert("Какие-то проблемы, попробуйте еще раз отправить.");
          }
        });
      }
    },
    addToDeal() {
      let data = {
        type: this.$route.params.type,
        dealId: this.$route.params.dealId,
        land: (this.choosedLand) ? this.choosedLand : null
      }
      this.loading = true;
      this.axios.post(this.url + "Bx24/addToDeal/", data).then((response) => {
        if (response.data.status) {
          alert('Участок добавлен в сделку');
        } else {
          alert('Произошла ошибка');
        }
        this.loading = false;
      });
    },
    updateColor() {
      let self = this;
      this.villagesSquareMapEdit.options.set({fillColor: self.colors.hex});
    },
    toogleCallback() {
      this.showCallback = (this.showCallback) ? false : true;
    },
    closeCallback() {
      this.showCallback = false;
    },
    closeDetailVill() {
      this.showDetailVill = false;
    },
    toogelFilter() {
      this.filter.show = (this.filter.show) ? false : true;
    },
    closeDetail() {
      this.choosedLand = false;
      this.addDiscuont = true;
    },
    goTo(e) {
      if (this.isMobile) this.showListVillages = false;
      let to = e.srcElement.dataset.to;
      this.setVillageToMap(to);
      this.goToVillage(to)
    },
    goToVillage(villageId) {
      this.loading = true;
      this.choosedLand = null;
      this.$router.push(
          {
            name: this.$route.name,
            params: {
              village: villageId,
            }
          }
      );
      this.landsChoseToEdit = [];
      this.choosedVill = villageId;
      this.currentVillage = this.hoverVillage = this.villages.find(vill => vill.ID === this.choosedVill);
      this.printVillage();
    },
    setVillageToMap(id) {
      let self = this;
      this.map.geoObjects.each(function (geoCollection) {
        geoCollection.each(function (ob) {
          if (ob.properties.get('ID') == id) {
            self.villageToMap = ob;
          }
        });
      });
    },
    toggleMobileListVillages() {
      this.showListVillages = (this.showListVillages) ? false : true;
      this.showAllVill = true;
    },
    toogleDetail() {
      this.showDetail = (this.showDetail) ? false : true;
      this.addDiscuont = true;
    },
    setCookie() {
      // this.$cookies.set("fromCoordinates", JSON.stringify(this.order.fromCoordinates), 36000000000);
      // this.$cookies.set("from", this.order.from, 36000000000);
      // this.$cookies.set("pointsCoordinates", JSON.stringify(this.order.pointsCoorinates), 36000000000);
      // this.$cookies.set("points", JSON.stringify(this.order.points), 36000000000);
      // this.$cookies.set("distanceFromMkad", this.order.distanceFromMkad, 36000000000);
    },
    setCoordinatesSquare() {
      let self = this;
      let newCoords = this.villagesSquareMapEdit.geometry.getCoordinates();
      let setCoords = [];
      newCoords[0].map(function (coord) {
        // window.console.log(coord);
        setCoords.push(coord[0] + ',' + coord[1]);
      });
      // window.console.log(setCoords);
      let data = {
        id: this.$route.params.village,
        prop: "PROPERTY_532",
        coords: setCoords
      }
      this.axios.post(this.url + "Bx24/saveCoordsVillage/", data).then((response) => {
        if (response.data.result) {
          data = {
            id: self.$route.params.village,
            prop: "PROPERTY_534",
            coords: self.colors.hex
          };
          self.axios.post(this.url + "Bx24/saveCoordsVillage/", data).then((response) => {
            if (response.data.result) {
              alert("Все сохранилось!");
            } else {
              alert("Какие-то проблемы, попробуйте еще раз.");
            }
          });
        } else {
          alert("Какие-то проблемы, попробуйте еще раз.");
        }
      });
    },
    setCoordinatesEdit() {
      let newCoords = this.villageOverlay.geometry.getBounds();
      newCoords = [
        newCoords[0][0] + "," + newCoords[0][1],
        newCoords[0][0] + "," + newCoords[1][1],
        newCoords[1][0] + "," + newCoords[1][1],
        newCoords[1][0] + "," + newCoords[0][1],
        newCoords[0][0] + "," + newCoords[0][1]
      ];
      let data = {
        id: this.$route.params.village,
        prop: "PROPERTY_506",
        coords: newCoords
      }
      this.axios.post(this.url + "Bx24/saveCoordsVillage/", data).then((response) => {
        if (response.data.result) {
          alert("Все сохранилось!");
        } else {
          alert("Какие-то проблемы, попробуйте еще раз.");
        }
      });
    },
    getPolygonCentroid(pts) {
      let first = pts[0], last = pts[pts.length - 1];
      if (first[0] !== last[0] || first[1] !== last[1]) pts.push(first);
      let twicearea = 0, x = 0, y = 0, p1, p2, f,
          nPts = pts.length;
      for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
        p1 = pts[i];
        p2 = pts[j];
        f = parseFloat(p1[0]) * parseFloat(p2[1]) - parseFloat(p2[0]) * parseFloat(p1[1]);
        twicearea += f;
        x += (parseFloat(p1[0]) + parseFloat(p2[0])) * f;
        y += (parseFloat(p1[1]) + parseFloat(p2[1])) * f;
      }
      f = twicearea * 3;
      return [x / f, y / f];
    },
    setLandsNumber() {
      let resultArr = [];
      let self = this;
      let numbers = window.document.querySelectorAll(".numbers");
      let index = 0, length = numbers.length;
      for (index; index < length; index++) {
        numbers[index].style.display = "none";
      }
      let zoom = this.map.getZoom();
      setTimeout(() => {
        this.lands.forEach(function (value) {
          let coords = value.PROPERTY_507;
          let center = self.getPolygonCentroid(coords)
          let number = value.PROPERTY_437.split("-");
          number = number[number.length - 1];
          // window.console.log(zoom);
          let content = window.ymaps.templateLayoutFactory.createClass('<div class="numbers numbers' + self.$route.params.village + '" style="color:#ffffff;font-size:10px;' + ((zoom >= 17) ? 'display:block' : '') + '">' + number + '</div>');
          let result = {
            data: value,
            coords: coords,
            id: value.ID,
            center: center,
            properties: {
              ID: value.ID,
              type: 'landNumber',
              vill: self.$route.params.village
            },
            options: {
              iconLayout: 'default#imageWithContent',
              iconImageHref: '',
              hasBalloon: false,
              hasHint: false,
              zIndex: -9,
              zIndexHover: -9,
              iconImageSize: [0, 0],
              iconOffset: [-number.length * 2.5, -5],
              iconContentLayout: content,
            },
          };
          resultArr.push(result);
        });
      }, 500);
      this.landsMap = resultArr
    },
    firstMapInit(map) {
      let self = this;
      let villageId = this.currentVillage.ID;
      this.map = map;
      self.map.events.add('boundschange', function (e) {
        let oldZoom = e.get("oldZoom");
        let newZoom = e.get("newZoom");
        if (self.newZoom !== newZoom && self.oldZoom !== oldZoom) {
          self.newZoom = newZoom;
          self.oldZoom = oldZoom;
          let numbers = window.document.querySelectorAll(".numbers" + self.$route.params.village);
          let index = 0, length = numbers.length;
          if (oldZoom > 15 && newZoom <= 15) {
            //window.console.log("Скрытие номера");
            for (index; index < length; index++) {
              numbers[index].style.display = "none";
            }
          } else if (oldZoom <= 15 && newZoom > 15) {
            //window.console.log("Показ номера");
            for (index; index < length; index++) {
              numbers[index].style.display = "block";
            }
          }
        }
      });

      setTimeout(() => {
        this.setVillageToMap(villageId);
        this.goToVillage(villageId);
        this.setLandsNumber();

        let edit = this.$route.path.includes('edit');
        let square = this.$route.path.includes('square');
        if (edit || square) {
          this.map.geoObjects.each(function (geoCollection) {
            geoCollection.each(function (ob) {
              if (ob.properties.get('type') === 'overlay' && edit) {
                self.villageOverlay = ob;
                ob.editor.startFraming();
              }
              if (ob.properties.get('type') === 'squareEdit' && square) {
                self.villagesSquareMapEdit = ob;
                ob.editor.startEditing();
              }
            });
          });
        }
      }, 500);

    },
    mapSetBounds() {
      if (this.villageToMap) {
        let self = this;
        let oldCenter = this.map.getCenter();
        let newCenter = this.currentVillage.PROPERTY_510;
        let lenght = Math.sqrt(Math.pow((oldCenter[0] - newCenter[0]), 2) + Math.pow((oldCenter[1] - newCenter[1]), 2));
        let duration = 1000;
        if (lenght > 1) {
          duration = 3000
        } else if (lenght > 0.48) {
          duration = 2200
        } else if (lenght > 0.40) {
          duration = 2000
        }
        this.map.panTo([oldCenter, newCenter], {
          checkZoomRange: true,
          duration: duration
        }).then(() => {
          self.map.setBounds(self.villageToMap.geometry.getBounds(), {
            checkZoomRange: true,
            duration: 2000,
            zoomMargin: [-150, 0, 0, 0],
          }).then(
              () => {
                self.loading = false;
              }
          );
        });
      }
    },
    getVillageHintContentHover(village) {
      return "<div style='font-family: Arial; background-color: #ffffff;position: absolute;left:0vh;top:-1vh; display: flex;border-radius: 4vh;padding: 1vh .5vh;align-items: center;'>" +
          "<div style='background-color: #FFC5A4; border-radius: 100%; position: absolute;left: 0;top: 0; height: 5vh; width: 5vh;display: flex;align-items: center;'>" +
          "<img style='margin: auto; height: 5vh' src='" + village.PROPERTY_516 + "'/> " +
          "</div>" +
          "<div style='font-size: 2.3vh;line-height: 3vh; margin-left: 7vh; margin-right: 2vh'>" +
          village.NAME +
          "</div>" +
          "</div>";
    },
    getLandFillColor(land) {
      return (land.PROPERTY_365 > 0 && parseInt(land.PROPERTY_363) === 299) ? this.color[1080] : this.color[land.PROPERTY_363];
    },
    getLandHintContentHover(land) {
      let showDiscount = 'display:none';
      if (land.PROPERTY_365 > 0) showDiscount = '';

      return "<div style='font-family: Arial; background-color: #E1E1E1;position: absolute;left:0;top:0;opacity: 0.9'>" +
          "<div style='font-size: 3.1vh;line-height: 1.35;margin: 2.3vh 3.9vh .8vh 2.3vh'><b>" +
          land.PROPERTY_366 + " соток</b>" +
          "<img style='margin-left: 2.3vh' src='/assets/images/arrowRight.svg'></div>" +
          "<div style='display: flex;margin: 1.3vh 3.9vh 2.3vh 2.3vh'>" +
          "<div style='font-size: 2.3vh;color:#1C100A;line-height: 1.23; font-weight: 400; opacity: .7'>" +
          this.roundingTen(parseFloat(land.PROPERTY_366) * parseFloat(land.PROPERTY_364) * (1 - parseFloat(land.PROPERTY_365) / 100)).toLocaleString() + " ₽</div>" +
          "<div style='color: #FF5C00;font-weight: 700;border: solid 2px #6B8F28; border-radius: .7vh;font-size: 1.8vh;line-height: 1.42; padding: 0 0.5vh;margin-left: 1.5vh;" +
          showDiscount + "'>-" + parseInt(land.PROPERTY_365) + "%</div>" +
          "</div>" +
          "</div>";
    },
    printVillage() {
      let self = this;
      let vill = (this.$route.params.village) ? "vill=" + this.$route.params.village : null;
      if (vill) this.showDetailVill = true;
      this.axios.get(this.url + "Bx24/getLands/?" + vill).then((response) => {
        if (response.data) {
          self.landsMap = [];
          self.lands = [];
          setTimeout(() => {
            self.lands = response.data;
            self.setLandsNumber();
            self.mapSetBounds();
          }, 2500);
          self.showMap = true;
          self.centerLands = [];

        } else {
          alert("Что-то пошло не так.");
        }
      });
    },
    clear() {
      this.lands = [];
    }
  },
  created() {
    this.isMobile = (screen.width <= 760);
    if (this.isMobile) this.showListVillages = false;
    // if (!this.$route.params.village) {
    //     this.$router.push("11053");
    // }
  },
  mounted() {
    let self = this;
    let vill = (this.$route.params.village) ? "vill=" + this.$route.params.village : null;
    let m2o2 = (this.$route.params.site == "342") ? "Y" : "N";
    this.axios.get(this.url + "Bx24/getVillages/?m2o2=" + m2o2).then((response) => {
      if (response.data) {
        self.villages = response.data;
        self.currentVillage = self.hoverVillage = self.villages.find(vill => vill.ID === self.choosedVill);
        self.axios.get(self.url + "Bx24/getLands/?" + vill).then((response) => {
          if (response.data) {
            self.lands = response.data;
            self.showMap = true;
            self.centerLands = [];
            self.startTimer();
          } else {
            alert("Что-то пошло не так.");
          }
        });
      } else {
        alert("Что-то пошло не так.");
      }
    });
    // let self = this;
    // self.$cookies.set("fromCoordinates", '', 36000000000);
    // self.$cookies.set("from", '', 36000000000);
    // self.$cookies.set("pointsCoordinates", '', 36000000000);
    // self.$cookies.set("points", '', 36000000000);
    // this.$cookies.set("distanceFromMkad", '', 36000000000);
    // this.$cookies.set("cart", this.cart, 36000000000);
  }
}
</script>

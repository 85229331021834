import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    left_sum: 0,
    cart: []
  },
  mutations: {
    SET_LEFT_SUM: (state, payload) => {
      state.left_sum = payload;
    },
    SET_CART: (state, payload) => {
      state.cart = payload;
    },
  },
  actions: {
    SET_LEFT_SUM (context, payload) {
      context.commit('SET_LEFT_SUM', payload);
    },
    SET_CART (context, payload) {
      context.commit('SET_CART', payload);
    },
  },
  getters: {
    left_sum: state => {
      return state.left_sum;
    },
    cart: state => {
      return state.cart;
    }
  }
})
